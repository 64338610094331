.search_button {
    width: 0;
    overflow: hidden;
    opacity: 0;
    margin-left: 0 !important;
    transition: all 0.3s ease;
}
  
.search_button.visible {
    margin-left: 8px !important;
    width: 200px; /* Заданная ширина кнопки */
    opacity: 1;
}