
@font-face {
    font-family: 'NordiquePro';
    src: url('./NordiquePro-Semibold.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Inter';
    src: url('./Inter_18pt-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}
  
@font-face {
    font-family: 'Inter';
    src: url('./Inter_18pt-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}
  
@font-face {
    font-family: 'Inter';
    src: url('./Inter_18pt-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
  
@font-face {
    font-family: 'Inter';
    src: url('./Inter_18pt-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}
  
@font-face {
    font-family: 'Inter';
    src: url('./Inter_18pt-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
  
@font-face {
    font-family: 'Inter';
    src: url('./Inter_18pt-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}
  
@font-face {
    font-family: 'Inter';
    src: url('./Inter_18pt-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
  
@font-face {
    font-family: 'Inter';
    src: url('./Inter_18pt-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}
  
@font-face {
    font-family: 'Inter';
    src: url('./Inter_18pt-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
  
@font-face {
    font-family: 'Inter';
    src: url('./Inter_18pt-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}
  
@font-face {
    font-family: 'Inter';
    src: url('./Inter_18pt-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
  
@font-face {
    font-family: 'Inter';
    src: url('./Inter_18pt-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}
  
@font-face {
    font-family: 'Inter';
    src: url('./Inter_18pt-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}
  
@font-face {
    font-family: 'Inter';
    src: url('./Inter_18pt-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}
  
@font-face {
    font-family: 'Inter';
    src: url('./Inter_18pt-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
  
@font-face {
    font-family: 'Inter';
    src: url('./Inter_18pt-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}
  
@font-face {
    font-family: 'Inter';
    src: url('./Inter_18pt-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
  
@font-face {
    font-family: 'Inter';
    src: url('./Inter_18pt-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}