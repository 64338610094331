html {
  height: 100vh;
  -webkit-overflow-scrolling: touch; /* Чтобы на iOS был нормальный скролл */
}

body {
  margin: 0;
  background-color: #f3f3f3;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch; /* Чтобы на iOS был нормальный скролл */

  --react-international-phone-border-radius: 12px;
  --react-international-phone-background-color: #E0E0E0;
  --react-international-phone-font-size: 15px;

  --swiper-pagination-color: var(--joy-palette-primary-50);
  --swiper-pagination-bullet-inactive-color: var(--joy-palette-neutral-200);
  --swiper-pagination-bullet-inactive-opacity: .8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.swiper {
  width: 100%;
  height: 100%;
}


.PhotoView-Portal {
  z-index: 13 !important;
}

/* .SwiperItem .swiper-wrapper {
  margin: 0 20px;
} */

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */

  display: flex;
  justify-content: center;
  align-items: center;
}

/* .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */



/* .ChipSwiper > .swiper-wrapper {
  margin: 0 15px !important;
} */


/* Стили для поля с номером */
.react-international-phone-input {
  width: 100%;
}



.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.open-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.SwiperCompanyPhoto .swiper-wrapper {
  width: auto;
}

.SwiperCompanyPhoto .swiper-wrapper > .swiper-slide {
  width: 100% !important;
  display: flex;
}

/* .sliding-page {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50vh;
  background: #ffffff;
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 15px 15px 0 0;
  display: flex;
  flex-direction: column;
} */

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.content {
  margin-top: 60px;
}







.TitleSection {
  position: absolute;
  display: flex;
  left: 0px;
  padding-left: 16px;
  top: 38px;
  padding-top: 9px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 43px;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
}

.Title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.Caption {
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
}

.DragHandleEdge {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  /* position: absolute; */
  /* top: 0;
  left: 0;
  right: 0; */
  height: 6px;
  /* background-color: red; */
  border-top: 2px solid transparent;
  transition: border-top 0.2s ease;
  cursor: row-resize;
}

.DragHandleEdge:hover {
  border-top: 2px solid #ebebeb;
}

.DragHandle {
  position: absolute;
  top: 24px;
  width: 24px;
  height: 4px;

  background: #e0e0e0;
  border-radius: 25px;
  transition: background 0.2s ease;
}

.DragHandle.left {
  margin-left: 22px;
  transform-origin: left;
  /* background: blue; */
}

.DragHandle.right {
  margin-right: 22px;
  transform-origin: right;
  /* background: red; */
}

.DragHandleEdge:hover .DragHandle {
  background: #b3b3b3;
}

.DragHandleEdge:active .DragHandle {
  background: #b3b3b3;
}

.Navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 150px;
  border-bottom: 1px solid #e0e0e0;
  padding: 0 16px;
}

.CloseIcon {
  font-family: -apple-system, SF Pro;
  color: #616161;
  transition: color 0.2s ease;
}

.CloseIcon:hover {
  color: #8d8d8d;
}

.ButtonExpandCollapse {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, SF Pro;
  color: #a5a5a5;
  transition: color 0.2s ease, background 0.4s ease;
  background: transparent;
  padding: 4px 8px;
  border-radius: 6px;
}

.ButtonExpandCollapse:hover {
  background: #ebebeb;
  color: #353535;
}

.IconExpandCollapse {
  font-family: -apple-system, SF Pro;
}

.LabelExpandCollapse {
  display: inline-block;
  font-size: 13px;
  max-width: 0;
  overflow: hidden;
  padding: 0;
  transition: max-width 0.4s ease, padding 0.4s ease;
}

.ButtonExpandCollapse:hover .LabelExpandCollapse {
  max-width: 100px;
  padding: 4px;
  transition: max-width 0.4s ease 1s, padding 0.4s ease 1s;
}

.ButtonOpenSheet {
  all: unset;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: absolute;
  width: 128px;
  height: 48px;
  left: calc(50% - 128px / 2);
  bottom: 16px;

  background: #ffffff;
  /* Border/Border main alpha */

  border: 1px solid rgba(0, 0, 0, 0.08);
  /* 6dp (Modals) */

  border-radius: 8px;

  font-family: -apple-system, SF Pro;
  color: #616161;
  transition: color 0.2s ease;
  cursor: pointer;
}

.ButtonOpenSheet:hover {
  background: #f5f5f5;
}

.ButtonGroup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.BottomSheet {
  display: inline-block;
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06), 0px 2px 13px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  z-index: 1000;
}
